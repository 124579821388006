exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-caniuse-tsx": () => import("./../../../src/pages/caniuse.tsx" /* webpackChunkName: "component---src-pages-caniuse-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-devis-tsx": () => import("./../../../src/pages/devis.tsx" /* webpackChunkName: "component---src-pages-devis-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-gdpr-tsx": () => import("./../../../src/pages/gdpr.tsx" /* webpackChunkName: "component---src-pages-gdpr-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-initialisation-tsx": () => import("./../../../src/pages/initialisation.tsx" /* webpackChunkName: "component---src-pages-initialisation-tsx" */),
  "component---src-pages-interview-tsx": () => import("./../../../src/pages/interview.tsx" /* webpackChunkName: "component---src-pages-interview-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-pricings-tsx": () => import("./../../../src/pages/pricings.tsx" /* webpackChunkName: "component---src-pages-pricings-tsx" */),
  "component---src-pages-references-tsx": () => import("./../../../src/pages/references.tsx" /* webpackChunkName: "component---src-pages-references-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-releases-tsx": () => import("./../../../src/pages/releases.tsx" /* webpackChunkName: "component---src-pages-releases-tsx" */),
  "component---src-pages-signature-tsx": () => import("./../../../src/pages/signature.tsx" /* webpackChunkName: "component---src-pages-signature-tsx" */),
  "component---src-pages-slide-tsx": () => import("./../../../src/pages/slide.tsx" /* webpackChunkName: "component---src-pages-slide-tsx" */),
  "component---src-pages-sports-basket-tsx": () => import("./../../../src/pages/sports/basket.tsx" /* webpackChunkName: "component---src-pages-sports-basket-tsx" */),
  "component---src-pages-sports-football-tsx": () => import("./../../../src/pages/sports/football.tsx" /* webpackChunkName: "component---src-pages-sports-football-tsx" */),
  "component---src-pages-sports-golf-tsx": () => import("./../../../src/pages/sports/golf.tsx" /* webpackChunkName: "component---src-pages-sports-golf-tsx" */),
  "component---src-pages-sports-handball-tsx": () => import("./../../../src/pages/sports/handball.tsx" /* webpackChunkName: "component---src-pages-sports-handball-tsx" */),
  "component---src-pages-sports-rugby-tsx": () => import("./../../../src/pages/sports/rugby.tsx" /* webpackChunkName: "component---src-pages-sports-rugby-tsx" */),
  "component---src-pages-sports-run-tsx": () => import("./../../../src/pages/sports/run.tsx" /* webpackChunkName: "component---src-pages-sports-run-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-survey-tsx": () => import("./../../../src/pages/survey.tsx" /* webpackChunkName: "component---src-pages-survey-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-ticket-tsx": () => import("./../../../src/pages/ticket.tsx" /* webpackChunkName: "component---src-pages-ticket-tsx" */),
  "component---src-pages-types-association-tsx": () => import("./../../../src/pages/types/association.tsx" /* webpackChunkName: "component---src-pages-types-association-tsx" */),
  "component---src-pages-types-company-tsx": () => import("./../../../src/pages/types/company.tsx" /* webpackChunkName: "component---src-pages-types-company-tsx" */),
  "component---src-pages-types-domainedunet-tsx": () => import("./../../../src/pages/types/domainedunet.tsx" /* webpackChunkName: "component---src-pages-types-domainedunet-tsx" */),
  "component---src-pages-types-restaurant-tsx": () => import("./../../../src/pages/types/restaurant.tsx" /* webpackChunkName: "component---src-pages-types-restaurant-tsx" */),
  "component---src-pages-types-school-tsx": () => import("./../../../src/pages/types/school.tsx" /* webpackChunkName: "component---src-pages-types-school-tsx" */),
  "component---src-pages-types-town-tsx": () => import("./../../../src/pages/types/town.tsx" /* webpackChunkName: "component---src-pages-types-town-tsx" */)
}

